import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import pdf from '../assets/file/listaQuickStartGuide.pdf';
import logo from '../assets/img/logo-white.png';
import UserNavigation from './account/UserNavigation';
import CurrentUserCan from './CurrentUserCan';
//import { Link } from 'react-router-dom';
import { VersionLink as Link } from './VersionLink';

const PrivateHeader = () => {
  const [isSticky, setSticky] = useState(false);
  const ref = useRef(null);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let lastScrollTop = 0;
  const handleScroll = () => {
    if (ref.current) {
      let st = window.pageYOffset || document.documentElement.scrollTop;
      if (st > lastScrollTop) {
        // downscroll code
        setSticky(false);
      } else {
        // upscroll code
        setSticky(true);
      }
      lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
      if (st <= 86) {
        setSticky(false);
        ref.current.classList.remove('scrolling');
      } else {
        if (!ref.current.classList.contains('scrolling')) {
          ref.current.className += ' scrolling';
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);
  let dashboardLinkClass = 'nav-link';
  {
    window.location.href.indexOf('/dashboard') !== -1
      ? (dashboardLinkClass = 'nav-link active')
      : (dashboardLinkClass = 'nav-link');
  }
  let procedureTemplatesLinkClass = 'nav-link';
  {
    window.location.href.indexOf('/procedures') !== -1
      ? (procedureTemplatesLinkClass = 'nav-link mr-auto active')
      : (procedureTemplatesLinkClass = 'nav-link mr-auto');
  }
  let purchasableProcedureTemplatesLinkClass = 'nav-link';
  {
    if (window.location.href.indexOf('/purchasable_procedures') !== -1) {
      purchasableProcedureTemplatesLinkClass = 'nav-link ml-auto active';
      procedureTemplatesLinkClass = 'nav-link mr-auto';
    } else {
      purchasableProcedureTemplatesLinkClass = 'nav-link ml-auto';
    }
  }
  let usedProceduresLinkClass = 'nav-link mr-auto';
  {
    window.location.href.indexOf('used_procedures') !== -1
      ? (usedProceduresLinkClass = 'nav-link active')
      : (usedProceduresLinkClass = 'nav-link');
  }
  return (
    <Navbar
      expand="lg"
      className={`sticky-wrapper${isSticky ? ' blue-sticky' : ''}`}
      ref={ref}
    >
      <div className={'custom-container'}>
        <Link to="/dashboard" className={'navbar-brand'}>
          <img src={logo} width={176} height={80} alt={'Lista'} />
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav>
            <Link to="/dashboard" className={dashboardLinkClass}>
              Dashboard
            </Link>
            <Link to="/used_procedures/" className={usedProceduresLinkClass}>
              {/* Procedures In Use */}
              {/*Active Procedures*/}
              Active Case Workflows
            </Link>
            <Link to="/procedures/" className={procedureTemplatesLinkClass}>
              {/* Procedure Templates */}
              {/*My Templates*/}
              My Case Workflows
            </Link>
            <Link
              to="/purchasable_procedures/"
              className={purchasableProcedureTemplatesLinkClass}
            >
              {/* Procedure Templates */}
              {/*Buy Templates*/}
              Buy Case Workflows
            </Link>
            <NavDropdown title={'Help'} id="help-collapsible-menu">
              {/* <a
                href={pdf}
                className={'dropdown-item quick-start-link'}
                target={'_blank'}
                rel="noopener noreferrer"
              >
                User Guide
              </a> */}
              <a
                href={'javascript:;'}
                className={'dropdown-item intro-video-link'}
                rel="noopener noreferrer"
                onClick={handleShow}
              >
                Watch Intro Video
              </a>
              <Modal show={show} onHide={handleClose} centered size="lg">
                <Modal.Header closeButton>
                  <Modal.Title>Watch Intro Video</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="intro-video intro-video-modal">
                    <div className="video-container">
                      <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/NjjEnKMH5dM"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button className={'btn btn-primary'} onClick={handleClose}>
                    Close
                  </button>
                </Modal.Footer>
              </Modal>
              <a
                href={'https://www.lista.io/product-faqs/'}
                className={'dropdown-item quick-start-link'}
                target={'_blank'}
                rel="noopener noreferrer"
              >
                FAQ
              </a>
              <a
                href="mailto:help@lista.io"
                className={'dropdown-item contact-us-link'}
                rel="noopener noreferrer"
              >
                Contact Us
              </a>
            </NavDropdown>
            <UserNavigation />
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};

export default PrivateHeader;
