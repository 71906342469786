import React, { Component } from 'react';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import {
  clone as cloneProcedure,
  reset as cloneReset,
  success as cloneSuccess
} from '../../actions/procedure/clone';
import { del, success as delSuccess } from '../../actions/procedure/delete';
import { list } from '../../actions/procedure/list';
import {
  create as createUsedProcedure,
  success as successUsedProcedure
} from '../../actions/usedprocedure/create';
import { getPageWithFilterValues } from '../../utils/listFilter';
import CurrentUserCan from '../CurrentUserCan';
import { VersionLink as Link } from '../VersionLink';

class ProcedureList extends Component {
  state = {
    procedureModals: {}
  };

  delNow = (item, event) => {
    event.target.disabled = true;
    event.target.innerHTML += '<span class="fa fa-spinner fa-spin"></span>';

    let delItem = this.props.del(item);

    if (typeof delItem.then === 'function') {
      delItem.then(() => {
        this.props.history.push('/');
        this.handleClose({ '@id': 'delete_' + item['@id'] });

        this.props.delReset();
      });
    }
  };

  getPageWithFilterValues = page => {
    return getPageWithFilterValues(
      'procedureFilterValues',
      page,
      '/procedures'
    );
  };

  setShow = (procedure, value) => {
    this.setState({
      procedureModals: {
        ...this.state.procedureModals,
        [procedure['@id']]: value
      }
    });
  };

  handleClose = procedure => this.setShow(procedure, false);

  handleShow = procedure => this.setShow(procedure, true);

  useProcedure = (item, event) => {
    event.preventDefault();

    const createUsedProcedurePromise = this.props.createUsedProcedure({
      procedure: item['@id']
    });
    createUsedProcedurePromise.then(response => {
      this.props.history.push(
        `/used_procedures/edit/${encodeURIComponent(response.created['@id'])}`
      );
    });
  };

  cloneProcedure = (item, event) => {
    event.preventDefault();
    this.props.cloneProcedure(item);
  };

  render() {
    if (this.props.usedProcedureCreated) {
      let usedProcedureCreatedId = this.props.usedProcedureCreated['@id'];

      this.props.successUsedProcedure(null);
      return (
        <Redirect
          to={`/used_procedures/edit/${encodeURIComponent(
            usedProcedureCreatedId
          )}`}
          push={true}
        />
      );
    }
    if (this.props.procedureCloned) {
      setTimeout(() => {
        // on the next page, focus on the name field to allow the user to quickly edit it
        let elems = document.getElementsByName('name');
        if (elems && elems.length > 0) {
          window.scrollTo(0, 0);
          elems[0].focus();
          elems[0].setSelectionRange(0, elems[0].value.length);
        }
      }, 1100);

      let procedureClonedId = this.props.procedureCloned['@id'];
      this.props.cloneSuccess(null);
      return (
        <Redirect
          to={`/procedures/edit/${encodeURIComponent(procedureClonedId)}`}
          push={true}
        />
      );
    }

    return (
      <div className="main-section template-wrapper">
        <div className="d-flex">
          <div className="list-icon procedure-list-icon"></div>
          {/* <h2 className="col-header">Procedure Templates</h2> */}
          {/*<h2 className="col-header">Templates</h2>*/}
          <h2 className="col-header">Case Workflows</h2>
        </div>
        <div className="procedure-list-table-wrapper">
          <table className="table table-responsive table-striped table-hover">
            <thead>
              <tr>
                <th>Title</th>
                <th>Author</th>
                <th>Updated</th>
                <th colSpan={3}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {this.props.procedure &&
                this.props.procedure['hydra:member']
                  .sort(
                    (a, b) => b.countOfUsedProcedures - a.countOfUsedProcedures
                  )
                  .map(item => (
                    <tr>
                      <th
                        scope="row"
                        key={item['@id']}
                        draggable={true}
                        id={item['@id']}
                      >
                        {item['name'].length < 21
                          ? item['name']
                          : `${item['name'].substring(0, 18)}...`}
                      </th>
                      <td>{item['createdByName']}</td>
                      <td>{item['updatedAtDate']}</td>

                      <td>
                        <Link
                          className={'primary-action'}
                          to={'#'}
                          onClick={event => this.useProcedure(item, event)}
                        >
                          <span className="fa fa-list" aria-hidden="true" />{' '}
                          <span>Use</span>
                        </Link>
                      </td>
                      <td>
                        <CurrentUserCan
                          perform={'procedure:edit'}
                          yes={() => (
                            <Link
                              className={'edit-action'}
                              to={`procedures/edit/${encodeURIComponent(
                                item['@id']
                              )}`}
                              onClick={this.onNavigateToEdit}
                            >
                              <span className="fa fa-edit" aria-hidden="true" />
                              <span>Edit</span>
                            </Link>
                          )}
                        />
                      </td>

                      <td>
                        <CurrentUserCan
                          perform={'procedure:edit'}
                          yes={() => (
                            <DropdownButton
                              id={
                                'dropdown-basic-button-' +
                                item['@id'].replace(/[^\d]*/, '')
                              }
                              title={
                                <span className={'fa fa-ellipsis-v'}>
                                  <span className={'sr-only'}>Actions</span>
                                </span>
                              }
                            >
                              <CurrentUserCan
                                perform={'procedure:clone'}
                                yes={() => (
                                  <Link
                                    className={'dropdown-item clone-action'}
                                    to={'#'}
                                    onClick={event =>
                                      this.cloneProcedure(item, event)
                                    }
                                  >
                                    <span
                                      className="fa fa-copy"
                                      aria-hidden="true"
                                    />{' '}
                                    <span>Duplicate</span>
                                  </Link>
                                )}
                              />
                              <CurrentUserCan
                                perform={'procedure:delete'}
                                yes={() => (
                                  <>
                                    <button
                                      className={'dropdown-item delete-action'}
                                      onClick={() =>
                                        this.handleShow({
                                          '@id': 'delete_' + item['@id']
                                        })
                                      }
                                    >
                                      <span
                                        className="fa fa-trash"
                                        aria-hidden="true"
                                      />{' '}
                                      <span>Delete</span>
                                    </button>
                                    <Modal
                                      show={
                                        this.state.procedureModals[
                                          'delete_' + item['@id']
                                        ] || false
                                      }
                                      onHide={() =>
                                        this.handleClose({
                                          '@id': 'delete_' + item['@id']
                                        })
                                      }
                                      centered
                                      size="xl"
                                    >
                                      <Modal.Header closeButton>
                                        <Modal.Title>
                                          Are you sure you want to delete this
                                          Procedure?
                                        </Modal.Title>
                                      </Modal.Header>
                                      <Modal.Body>
                                        <p>This will delete:</p>
                                        <ul>
                                          <li>This Procedure</li>
                                          <li>All Steps in this Procedure</li>
                                        </ul>
                                      </Modal.Body>
                                      <Modal.Footer>
                                        <button
                                          className={'btn'}
                                          onClick={() =>
                                            this.handleClose({
                                              '@id': 'delete_' + item['@id']
                                            })
                                          }
                                        >
                                          Cancel
                                        </button>
                                        <button
                                          className={'btn btn-danger'}
                                          onClick={event =>
                                            this.delNow(item, event)
                                          }
                                        >
                                          {/* Delete Procedure */}
                                          {/*Delete Template*/}
                                          Delete Case Workflow
                                        </button>
                                      </Modal.Footer>
                                    </Modal>
                                  </>
                                )}
                              />
                            </DropdownButton>
                          )}
                        />
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
        <div className="button-wrapper">
          <div className="col-item">
            <Link to={`/procedures/`} className="btn btn-primary view-all-btn">
              View All&nbsp;&nbsp;&nbsp;
              <span className="fa fa-arrow-right" aria-hidden="true" />
            </Link>
          </div>
          <div className="col-item">
            <Link to={`/procedures/create`} className="btn btn-cta create-btn">
              <span className="fa fa-plus" aria-hidden="true" />
              &nbsp;&nbsp;&nbsp;Create New
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUserAccount: state.account.show.retrieved,
    procedureCloned: state.procedure.clone.cloned,
    usedProcedureCreated: state.usedprocedure.create.created
  };
};

const mapDispatchToProps = dispatch => ({
  delReset: () => dispatch(delSuccess(null)),

  createUsedProcedure: values => dispatch(createUsedProcedure(values)),
  successUsedProcedure: values => dispatch(successUsedProcedure(values)),

  cloneProcedure: item => dispatch(cloneProcedure(item)),
  cloneSuccess: values => dispatch(cloneSuccess(values)),
  cloneReset: eventSource => dispatch(cloneReset(eventSource)),

  del: item => dispatch(del(item)),

  list: page => dispatch(list(page))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProcedureList);
